import {
  FETCH_ABOUT_COMPANY,
  FETCH_ABOUT_FEATURE,
  FETCH_CLIENT_SAYS,
  FETCH_HOME_BANNER,
  FETCH_HOME_SERVICE, FETCH_OUR_PRODUCT,
  FETCH_PARTNER_COUNT,
  FETCH_TEAM_MEMBER
} from "../types";

const initialState = {
 homeBanner : [],
 partnerCount: [],
 aboutCompany: [],
 feature:[],
 homeService: [],
 ourProduct: [], 
 clientSays: [],
 teamMember: [],
  };
  
  const homeReducer = (state = initialState, action = {}) => {
    switch (action.type) {
       
        case FETCH_HOME_BANNER:
          return {
            ...state,
            homeBanner: action.payload
          };
        case FETCH_PARTNER_COUNT:
          return {
            ...state,
            partnerCount: action.payload
          };
        case FETCH_ABOUT_COMPANY:
          return {
            ...state,
            aboutCompany: action.payload
          };
        case FETCH_ABOUT_FEATURE:
          return {
            ...state,
            feature: action.payload
          };
        case FETCH_HOME_SERVICE:
          return {
            ...state,
            homeService: action.payload
          };
        case FETCH_OUR_PRODUCT:
          return {
            ...state,
            ourProduct: action.payload
          };
        case FETCH_CLIENT_SAYS:
          return {
            ...state,
            clientSays: action.payload
          };
      
          case FETCH_TEAM_MEMBER:
            return {
              ...state,
              teamMember: action.payload
            };
      default:
        return state;
    }
  };
  
  export default homeReducer;

  