import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BannerCreateUpdateForm from '../../../components/admin/AdminForms/BannerCreateUpdateForm'
import PageHeader from '../../../components/admin/layout/PageHeader'
import auth from '../../../utill/auth'
import AdminLayout from '../AdminLayout'

const AddHomeBanner = () => {
    const navigate = useNavigate()
    const [serviceList, setserviceList] = useState([]);
    const [bannerBody, setbannerBody] = useState();
    useEffect(() => {
      axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/service`, {
          headers: {
            Authorization: "Bearer" + auth.getToken(),
          },
        })
        .then((res) => {
          setserviceList(res.data);
         console.log(res.data);
        })
        .catch((err) => console.log(err));
    }, []);
    const onSubmit = async (data) => {
        console.log(data);
        const formData = new FormData();
        formData.append("title", data.title);
        if (data.photo.length > 0) {
          formData.append("photo", data.photo[0]);
        } else {
          formData.append("photo", data?.photo[0]);
        }
        formData.append("sub_title", data.sub_title);
        formData.append("body", bannerBody);
        formData.append("service_id", data?.service_id?.value);
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/api/banner`, formData, {
            headers: {
              Authorization: "Bearer" + auth.getToken(),
            },
          })
          .then((res) => {
            console.log(res.data);
            navigate("/admin/banner-list");
          })
          .catch((err) => console.log(err));
      };
  return (
    <AdminLayout>
    <PageHeader name1='Banner List' link1={`/admin/banner-list`}  name2='add banner' link2={`/admin/add-banner`} /> 
    <div className="ms-3">
      <h6>Add Banner</h6>
      <BannerCreateUpdateForm onSubmit={onSubmit} 
      serviceList={serviceList}
      bannerBody={bannerBody}
      setbannerBody={setbannerBody}
      />
    </div>
  </AdminLayout>
  )
}

export default AddHomeBanner