import * as actionType from '../types';

const initialState = {
 
 footerHeader : [],
//  isLoading: false,
//     error: null,
  };
  
  const footerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_FOOTER_HEADER_SUCCESS:
        //   console.log(action);
        return {
          ...state,
         footerHeader: action.payload
        };
      default:
        return state;
    }
  };
  
  export default footerReducer;

  