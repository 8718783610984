import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import DeleteModal from '../../../components/admin/deleteModal/DeleteModal'
import PageHeader from '../../../components/admin/layout/PageHeader'
import ModalContainer from '../../../components/common/ModalContainer'
import auth from '../../../utill/auth'
import AdminLayout from '../AdminLayout'

const HomeBannerList = () => {
    const [isModalOpen, setisModalOpen] = useState(false);
  const [selectedId, setselectedId] = useState();
  const [getBannerList, setgetBannerList] = useState([]);
  function closeModal() {
    setisModalOpen(false);
  }
  const getBannerListData = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/banner
      `, {
        headers: {
          Authorization: "Bearer" + auth.getToken(),
        },
      })
      .then((res) => {
        setgetBannerList(res.data);
       console.log(res.data);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  useEffect(() => {
    getBannerListData();
  }, []);
  const handleDeleteModal = (id) => {
    setisModalOpen(true);
    setselectedId(id);
  };
  const handleDeleteItem = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/api/banner/${selectedId}`, {
        headers: {
          Authorization: "Bearer" + auth.getToken(),
        },
      })
      .then((_) => {
        //console.log("banner deleted");
        setisModalOpen(false);
        getBannerListData()
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  
  
  return (
    <AdminLayout>
    <PageHeader name1='banner List' link1={`/admin/banner-list`}/>
    <div className="container-fluid py-4">
      <div className="text-end mb-4 ">
        {" "}
        <Link to="/admin/add-banner">
          <button className="bg-gradient-primary text-white px-4 py-2 border-radius-lg">
            {" "}
            Add Banner
            <span className="text-white mx-1">
              <i className="fa-regular fa-plus"></i>
            </span>
          </button>
        </Link>
      </div>
      {getBannerList?.data?.length ? 
      <div className="row">
        <div className="col-12">
          <div className="card my-4">
            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                <h6 className="text-white text-capitalize ps-3">
                  Banner List
                </h6>
              </div>
            </div>
            <div className="card-body px-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table ">
                  <thead>
                    <tr>
                     
                      <th className=" opacity-7 ">
                        photo
                      </th>
                      <th className=" opacity-7 ">
                        title
                      </th>
                     
                      <th className=" opacity-7"></th>                    
                        </tr>
                  </thead>
                  <tbody>
                    {getBannerList?.data?.map((banner) => (
                      <tr key={banner.id}>
                        <td className="">
                          <img
                            className=""
                            width={60}
                            src={`${process.env.REACT_APP_BACKEND_URL}/${banner?.photo}`}
                            alt=""
                          />
                        </td>
                        <td className="">
                          {banner.title}
                        </td>
                       
                      

                        <td className="">
                          {" "}
                          <div className='d-flex justify-content-around'>
                          {/* <NavLink
                              to={`/admin/banner-details/${banner.id}`}
                            >
                              <i className="fa-solid fa-eye"></i>
                            </NavLink> */}
                          <NavLink to={`/admin/edit-banner/${banner.id}`}>
                            <i className="fa-regular fa-pen-to-square"></i>
                          </NavLink>
                          <NavLink onClick={() => handleDeleteModal(banner.id)}>
                            {" "}
                            <i className="fa-solid fa-trash"></i>
                          </NavLink>
                          </div>
                        </td>

                        {
                          <ModalContainer
                          admin
                            isModalOpen={isModalOpen}
                            setisModalOpen={setisModalOpen}
                            closeModal={closeModal}
                          >
                            <DeleteModal
                              closeModal={closeModal}
                              handleDeleteItem={handleDeleteItem}
                            />
                          </ModalContainer>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      <h4 className="text-center">
        Empty List
        </h4> 
   }
     
    </div>
  </AdminLayout>
  )
}

export default HomeBannerList