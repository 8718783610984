import * as actionType from '../types';

const initialState = {
 headerMenu : [],
//  isLoading: false,
//     error: null,
  };
  
  const headerReducer = (state = initialState, action) => {
    switch (action.type) {
        // case actionType.FETCH_HEADER_MENU:
        //     return {
        //       ...state,
        //       isLoading: true,
        //     };
        case actionType.FETCH_HEADER_MENU_SUCCESS:
            
          return {
            ...state,
            // isLoading: false,
           headerMenu: action.payload
          };
        //   case actionType.FETCH_HEADER_MENU_FAILURE:
        // return {
        //   ...state,
        //   isLoading: false,
        //   error: action.payload,
        // };
      
      default:
        return state;
    }
  };
  
  export default headerReducer;

  