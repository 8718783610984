import {
    FETCH_ALL_PRODUCT,
    FETCH_ALL_SERVICES, FETCH_CATEGORIES_NAME, FETCH_CONSULTING_STEPS, FETCH_IT_CONSULTING_STEPS
} from "../types";

const initialState = {
 services : [],
 consultingSteps: [],
 itConsultingSteps: [],
 products: [],
 categoriesName: [],
 
  };
  
  const serviceReducer = (state = initialState, action = {}) => {
    switch (action.type) {
       
        case FETCH_ALL_SERVICES:
          return {
            ...state,
            services: action.payload
          };
        case FETCH_CONSULTING_STEPS:
          return {
            ...state,
            consultingSteps: action.payload
          };
        case FETCH_IT_CONSULTING_STEPS:
          return {
            ...state,
            itConsultingSteps: action.payload
          };
        case FETCH_ALL_PRODUCT:
          return {
            ...state,
            products: action.payload
          };
        case FETCH_CATEGORIES_NAME:
          return {
            ...state,
            categoriesName: action.payload
          };
       
      
      default:
        return state;
    }
  };
  
  export default serviceReducer;

  