import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import reduces from './reducers/rootrRducer';
const middleware = [thunk];
const initialState = {};
// const store = createStore(reduces, composeWithDevTools());

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(reduces, initialState, enhancer);
export default store;