import React from "react";

const DeleteModal = ({ closeModal, handleDeleteItem }) => {
  return (
    <div className="modal_delete ">
      {/* d-flex justify-content-center  align-items-center */}
        <div className="text-end">
          <button onClick={closeModal} className="close-btn bg-dark p-1 rounded-circle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#fff"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      <div className="my-5">
        <h5 className="text-center">Are you sure to delete?</h5>
        <div className="d-flex justify-content-center gap-3">
          <button
            onClick={ handleDeleteItem }
            className="bg-success text-white px-4 py-1 border-radius-lg"
          >
         <small>Yes</small>
          </button>
          <button
            onClick={closeModal}
            className=" bg-danger text-white px-4 py-1 border-radius-lg"
          >
           <small>No</small>
          </button>{" "}
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
