import {
    FETCH_COMPANY_ADDRESS
} from "../types";

const initialState = {
 address : [],
 
 
  };
  
  const contactReducer = (state = initialState, action = {}) => {
    switch (action.type) {
       
        case FETCH_COMPANY_ADDRESS:
          return {
            ...state,
            address: action.payload
          };
     
       
      
      default:
        return state;
    }
  };
  
  export default contactReducer;

  