import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BannerCreateUpdateForm from '../../../components/admin/AdminForms/BannerCreateUpdateForm'
import PageHeader from '../../../components/admin/layout/PageHeader'
import auth from '../../../utill/auth'
import AdminLayout from '../AdminLayout'

const EditHomeBanner = () => {
    const {id}  = useParams()
    const navigate = useNavigate()
  
  const [banner, setbanner] = useState();
  const [serviceList, setserviceList] = useState([]);
  const [bannerBody, setbannerBody] = useState();

  const getBanner = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/banner/${id}`, {
        headers: {
          Authorization: "Bearer" + auth.getToken(),
        },
      })
      .then((res) => {
        setbanner(res.data);
        //console.log(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
   
    getBanner();
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/service`, {
        headers: {
          Authorization: "Bearer" + auth.getToken(),
        },
      })
      .then((res) => {
        setserviceList(res.data);
       console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
    const onSubmit = async (data) => {
        console.log(data);
        const formData = new FormData();
        formData.append("title", data.title);
        if (data.photo.length > 0) {
          formData.append("photo", data.photo[0]);
        } else {
          formData.append("photo", banner?.photo[0]);
        }

        formData.append("service_id", data?.service_id?.value);
        formData.append("sub_title", data.sub_title);
        formData.append("body", bannerBody);
        formData.append("_method", "PUT");
     
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/api/banner/${id}`, formData, {
            headers: {
              Authorization: "Bearer" + auth.getToken(),
            },
          })
          .then((res) => {
            console.log(res.data);
            navigate("/admin/banner-list");
          })
          .catch((err) => console.log(err));
      };
  return (
    <AdminLayout>
    <PageHeader name1='Banner List' link1={`/admin/banner-list`}  name2='edit banner' link2={`/admin/edit-banner/${id}`} /> 
    <div className="ms-3">
      <h6>Edit Banner</h6>
      <BannerCreateUpdateForm edit onSubmit={onSubmit} banner={banner} 
      serviceList={serviceList}
      bannerBody={bannerBody}
      setbannerBody={setbannerBody}
      />
    </div>
  </AdminLayout>
  )
}

export default EditHomeBanner