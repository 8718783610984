import * as actionType from '../types';
const initialState = {
     totalPrice: 0
  };
  
  export const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_TOTAL_PRICE:
         //console.log(action.payload);
        return {
          ...state,
          totalPrice: action.payload
        };
     
      default:
        return state;
    }
  };
  