import React from "react";
import Modal from "react-modal";
Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    marginRight: "-40%",
    transform: "translate(-50%, -50%)",
    border: 0,
    maxWidth:"810px",
    padding: '0px',
    overflow: 'inherit',
    height: 'fit-content'
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9999,
  },
};
const profileStyles = {
  content: {
    top: "50%",
    left: "50%",
    bottom: "auto",
    marginRight: "-40%",
    transform: "translate(-50%, -50%)",
    border: 0,
    maxWidth:"auto",
    padding: '30px',
    // overflow: 'inherit',
    height: 'auto'
  },
  overlay: {
    backgroundColor: "rgba(74, 74, 74, 0.35)",
    zIndex: 9999,
  },
};
const customStyles1 = {
  content: {
    
    top: "50%",
    left: "50%",
    // bottom:"200px",
    transform: "translate(-50%, -50%)",
    border: 0,
    width:"280px",
    height:'200px',
    padding: '0px',
    overflow: 'inherit'
    
  },
  overlay: {
    backgroundColor: "rgba(74, 74, 74, 0.05)",
    zIndex: 999,
  },
};

const ModalContainer = ({ isModalOpen, children, closeModal, exam, admin, newsletter, profile }) => {
  //   let subtitle;

  //   function openModal() {
  //     setisModalOpen(true);
  //   }

  //   function afterOpenModal() {
  //     // references are now sync'd and can be accessed.
  //     subtitle.style.color = "#f00";
  //   }

  return (
    <Modal
      isOpen={isModalOpen}
      //   onAfterOpen={afterOpenModal}
      onRequestClose={ !exam ? closeModal  : ""}
      contentLabel="Example Modal"
      style={ (admin && customStyles1) || (profile && profileStyles) || customStyles  }
    >
      {children}
    </Modal>
  );
};

export default ModalContainer;
