import "./App.scss";
import RouteApp from "./route";

function App() {
  
  return (
   
    <div className="App">
      <RouteApp />
    </div>
  );
}

export default App;
